import { ListItem, UnorderedList } from '@chakra-ui/react';
import { useMemo } from 'react';

export default function FormErrors(props) {
  const errorsArray = useMemo(() => {
    if (!props.errors) {
      return [];
    }

    const errorsArray = [];
    if (!props.errors?.types && props.errors?.type) {
      if (props.errors.message) {
        errorsArray.push(props.errors.message);
      }

      if ('required' === props.errors?.type) {
        errorsArray.push('Ce champ ne peut pas être vide.');
      }

      return errorsArray;
    }

    for (let [errorName, errorValue] of Object.entries(props.errors?.types)) {
      if (typeof errorValue === 'string') {
        errorsArray.push(errorValue);
        continue;
      }

      if (errorName === 'required') {
        errorsArray.push('Valeur requise');
        continue;
      }

      if (errorValue === 'manual') {
        errorsArray.push(errorName);
        continue;
      }

      if (typeof errorValue === 'boolean') {
        errorsArray.push(errorName);
        continue;
      }

      errorsArray.push(errorValue);
    }

    return errorsArray;
  }, [props.errors]);

  if (errorsArray.length === 0) {
    return null;
  }

  return (
    <UnorderedList>
      {errorsArray.map((error, index) => {
        return <ListItem key={index}>{error}</ListItem>;
      })}
    </UnorderedList>
  );
}
