import {
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Box,
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/layout';
import { NotificationDot } from './NotificationDot';
import { GRAY, LIGHT, LIGHTER_GRAY, PRIMARY, SECONDARY, TERTIARY } from '../appTheme';
import { Link as NextLink } from 'next/link';
import { Icon } from './Icon';

export const InfoCardCell = ({ as, href, hasBgWhite, date, hasNotificationDot, notificationDotColor, children, attachments }) => {
  let listItems = [];
  let listItemsLength = 0;
  if(attachments){
    listItems = attachments.map(
      (attachment) => {
        return (
          <Box as="span" display="block">
            <Link as={NextLink} href={attachment.fileUrl}>
              <Box _hover={{color: PRIMARY}}>
                {attachment.fileName}
                {' '}
                <Box as="span" fontWeight="normal" fontSize="smaller">
                  ({attachment.fileWeight})
                </Box>
              </Box>
            </Link>
          </Box>
        )
      }
    );
    listItemsLength = attachments.length;
  }
  return (
    <Flex
      bg={hasBgWhite ? LIGHT : "none"} 
      width="100%" 
      alignItems="flex-start"
      justifyContent="space-between"
      flexDirection={{base: "column", md: "row"}} 
      gap={{base: ".5rem", md: "2rem"}} 
      padding=".5rem 1.5rem"
      // borderRadius="0 0 1.25rem 1.25rem"
      color={GRAY}
      _first={{ paddingBottom: ".75rem", paddingTop: ".75rem" }}
      _last={{ paddingBottom: "1rem" }}
    >
      <Flex
        as="span"
        alignItems="center"
        gap=".5rem"
        order={{ base: 2, md: 'revert' }}
        width="100%"
      >
        {hasNotificationDot &&
          <NotificationDot color={notificationDotColor}/>
        }
          <Flex as="span" flexDirection="column" width="100%">
            <Box
              as={as ? as : (href ? "a" : "span")}
              href={href ? href : null}
              _hover={{color: (href ? PRIMARY : null)}}
            >
              {children}
            </Box>
          </Flex>
      </Flex>
      {listItemsLength>=1 &&
        <Popover trigger="hover" as="span" placement="top">
          <PopoverTrigger as="span">
            <Box
              as="span"
              display="inline-flex"
              alignItems="center"
              position="relative"
              bottom="-.125em"
              cursor="pointer"
              pb=".25em"
              color={SECONDARY}
              _hover={{
                color: TERTIARY,
              }}
            >
              <Icon
                iconName="documents"
                iconWidth="1.25em"
                margin="0 .5em -.125em 0"
                verticalAlignMiddle
              />
              <Box as="span" lineHeight={0}>
                ({listItemsLength})
              </Box>
            </Box>
          </PopoverTrigger>
          <PopoverContent
            as="span"
            borderColor={LIGHTER_GRAY}
            fontWeight="bold"
            maxWidth={{ base: '16rem', sm: 'revert' }}
          >
            <PopoverArrow
              borderRight={
                'solid 1px ' + LIGHTER_GRAY
              }
              borderBottom={
                'solid 1px ' + LIGHTER_GRAY
              }
            />
            <PopoverHeader as="span">Pièce(s) jointe(s)</PopoverHeader>
            <PopoverBody as="span">
              {listItems}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }
      {date && (
      <Text
        as="strong"
        textAlign="right"
        order={{ base: 1, md: 'revert' }}
      >
        {date}
      </Text> 
      )}
    </Flex>
  )
}