import { Box } from '@chakra-ui/react';
import { SUCCESS } from '../appTheme';

export const NotificationDot = ({ color }) => (
  <Box
    as="span"
    display="inline-block"
    minWidth="1rem"
    minHeight="1rem"
    bg={color ? color : SUCCESS} 
    borderRadius="100%"
  />
);