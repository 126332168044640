import React, { useRef, useState } from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import {LIGHT, LIGHTER_GRAY, TERTIARY} from '../../../appTheme';
import { AgiresButton } from '../../AgiresButton';
import { useToast, VisuallyHidden } from '@chakra-ui/react';
import EditTodoItemForm from './EditTodoItemForm';
import ConfirmDeleteModal from '../Modal/ConfirmDeleteModal';

export default function TodoItem({ todoItem, removeTodoItem, onChange }) {
  const [confirmDeleteTodoItemId, setConfirmDeleteTodoItemId] = useState(null);
  const [isEditable, setIsEditable] = useState(null);
  const cancelRef = useRef();
  const toast = useToast();

  const sendTodoItemDeleting = async (id) => {
    const result = await fetch(`/api/todo_items`, {
      method: 'DELETE',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await result.json();
    return {
      status: result.status,
      headers: result.headers,
      body,
    };
  };

  return (
    <>
      {confirmDeleteTodoItemId && (
        <ConfirmDeleteModal
          title="Souhaitez vous confirmer la suppression ?"
          isOpen={true}
          cancelRef={cancelRef}
          onClose={() => setConfirmDeleteTodoItemId(null)}
          onConfirm={async () => {
            const result = await sendTodoItemDeleting(todoItem.id);
            if (result.status === 200) {
              toast({
                title: "L'action à faire bien été supprimée.",
                status: 'success',
                isClosable: true,
              });
              removeTodoItem(todoItem.id);
              onChange();
              setConfirmDeleteTodoItemId(null);
            }
          }}
        />
      )}
      <Box>
        {isEditable ? (
          <EditTodoItemForm
            todoItem={todoItem}
            onChange={onChange}
            dropEdition={() => setIsEditable(null)}
          />
        ) : (
          <Flex
            cursor={'default'}
            justifyContent="space-between"
            pl={4}
            pr={4}
            py={2}
            borderLeft="solid .125rem transparent"
            borderBottomWidth="1px"
            borderBottomColor={LIGHTER_GRAY}
            paddingBottom={2}
            display={{ base: 'block', md: 'flex' }}
            bgColor={LIGHT}
          >
            {todoItem.description}
            <Flex gap={1}>
              <AgiresButton
                iconName="material-icon-delete"
                colorScheme="danger_scheme"
                size="xs"
                minWidth={1}
                paddingInlineStart=".25em"
                paddingInlineEnd="0"
                onClick={async () => {
                  setConfirmDeleteTodoItemId(todoItem.id);
                }}
              >
                <VisuallyHidden>Supprimer</VisuallyHidden>
              </AgiresButton>
            </Flex>
          </Flex>
        )}
      </Box>
    </>
  );
}

export const MemoizedTodoItem = React.memo(TodoItem);
