import { Box, Flex } from '@chakra-ui/layout';
import { FormInput } from '../../FormInput';
import FormErrors from '../FormErrors';
import { FormTextArea } from '../../FormTextArea';
import { AgiresButton } from '../../AgiresButton';
import React from 'react';
import { useForm } from 'react-hook-form';
import { handleFormErrors } from '../../../common/form/formHelper';
import { useToast } from '@chakra-ui/react';

export default function AddTodoItemForm({
  addTodoItem,
  user,
  onChange,
  dropForm,
}) {
  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = useForm({});
  const toast = useToast();

  const sendTodoItemAdding = async (userId, description) => {
    const result = await fetch(`/api/todo_items`, {
      method: 'POST',
      body: JSON.stringify({
        description,
        userId,
      }),
    });

    const body = await result.json();
    return {
      status: result.status,
      headers: result.headers,
      body,
    };
  };

  const onSubmit = async (data) => {
    const result = await sendTodoItemAdding(user.id, data.description);

    if (result.status === 400) {
      handleFormErrors(result.body.errors, result.headers, setError, {}, toast);

      return;
    }

    if (result.status === 200) {
      toast({
        title: "L'action à faire a bien été ajoutée.",
        status: 'success',
        isClosable: true,
      });

      dropForm();
      onChange();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        marginBottom={2}
        pl={4}
        pr={4}
        py={2}
        borderLeft="solid .125rem transparent"
      >
        <FormInput
          {...register('description', {
            required: true,
          })}
          iconName="tasks"
          placeholder="Description"
          autoFocus={true}
          errorMessage={
            errors?.description && <FormErrors errors={errors.description} />
          }
          formgroupstatus={errors?.description && 'invalid'}
        />
        <Flex gap={3} justifyContent={'flex-end'}>
          <AgiresButton
            iconName="add"
            colorScheme="secondary_scheme"
            size="sm"
            paddingInlineStart=".5em"
            type="submit"
          >
            Valider
          </AgiresButton>
          <AgiresButton
            iconName="remove"
            colorScheme="danger_scheme"
            size="sm"
            paddingInlineStart=".5em"
            onClick={() => dropForm()}
          >
            Annuler
          </AgiresButton>
        </Flex>
      </Box>
    </form>
  );
}
