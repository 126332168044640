import {Flex, Link, Text, Grid, useTheme} from '@chakra-ui/react';
import { Icon } from './Icon';
import {
  BOX_SHADOW,
  LIGHT,
  PRIMARY,
  PRIMARY_LIGHTER, PRIMARY_SCHEME,
  SECONDARY,
  SECONDARY_LIGHTER, SECONDARY_SCHEME
} from '../appTheme';

export const InfoCard = ({
  children,
  cardTitle,
  cardIconName,
  linkTitle,
  linkHref,
  linkAs,
  headerColor,
  backgroundColor,
  bgGradient,
  nbOfColumns
}) => {
  const theme = useTheme();

  return <Flex
      flexGrow={1}
      width="100%"
      flexWrap="wrap"
      flexDirection="column"
      borderRadius="1.25rem"
      boxShadow={BOX_SHADOW}
      backgroundColor={backgroundColor ? backgroundColor : null}
      bgGradient={bgGradient}
  >
    <Flex
        bg={headerColor === SECONDARY ? SECONDARY : PRIMARY}
        flexDirection={{base: "column", md: "row"}}
        gap={{base: ".5rem", md: "revert"}}
        alignItems={{base: "flex-start", md: "center"}}
        padding=".75rem 1rem"
        justifyContent="space-between"
        width="100%"
        borderRadius="1.25rem 1.25rem 0 0"
    >
      <Flex alignItems="center">
        <Icon
            iconName={cardIconName}
            iconWidth="2rem"
            iconColor={theme.colors.light}
            margin="0 .5rem 0 0"
        />
        <Text
            lineHeight="none"
            display="inline-block"
            as="span"
            marginRight=".5rem"
            fontSize="1.375rem"
            fontWeight="bold"
            color={LIGHT}
        >
          {cardTitle}
        </Text>
      </Flex>
      {linkTitle && linkHref && (
          <>
            <Link
                as={linkAs}
                href={linkHref}
                bg={headerColor === SECONDARY ? SECONDARY_LIGHTER : PRIMARY_LIGHTER}
                color={LIGHT}
                borderRadius=".5rem"
                padding=".25rem .5rem"
                fontWeight="bold"
                minWidth="10rem"
                textAlign="center"
                display="inline-block"
                fontSize=".875rem"
                textDecoration="none"
                _hover={{
                  backgroundColor:
                      headerColor === SECONDARY ? `${SECONDARY_SCHEME}.600` : `${PRIMARY_SCHEME}.600`
                }}
            >
              {linkTitle}
            </Link>
          </>
      )}
    </Flex>
    <Grid
        templateColumns={{base: "repeat(1, 1fr)", md: `repeat(${nbOfColumns ? nbOfColumns : 1}, 1fr)`}}
        width="100%"
        flexGrow={1}
    >
      {children}
    </Grid>
  </Flex>
};

