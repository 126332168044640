import React from 'react';
import { useMemo } from 'react';

export default function FormattedDate({ date, showTime = false }) {
  if (typeof date.getMonth !== 'function') {
    date = new Date(date);
  }

  const formattedDate = useMemo(() => {
    return date.toLocaleDateString(
      'fr-FR',
      showTime && { hour: '2-digit', minute: '2-digit' }
    );
  }, [date]);
  return formattedDate;
}
