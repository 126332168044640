import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/layout';
import { FormInput } from '../../FormInput';
import FormErrors from '../FormErrors';
import { AgiresButton } from '../../AgiresButton';
import { Grid, useToast, VisuallyHidden } from '@chakra-ui/react';
import { handleFormErrors } from '../../../common/form/formHelper';
import { FormTextArea } from '../../FormTextArea';
import {
  GRAY_SCHEME,
  GRAY_VARIANT,
  SUCCESS_SCHEME,
  TERTIARY,
} from '../../../appTheme';
import { Icon } from '../../Icon';

export default function TodoItemEditForm({ todoItem, onChange, dropEdition }) {
  const defaultValues = useMemo(() => {
    return {
      description: todoItem.description,
    };
  }, []);
  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = useForm({ defaultValues });
  const toast = useToast();

  const sendTodoItemUpdating = async (id, description) => {
    const result = await fetch(`/api/todo_items/edit`, {
      method: 'POST',
      body: JSON.stringify({
        id,
        description,
      }),
    });

    const body = await result.json();
    return {
      status: result.status,
      headers: result.headers,
      body,
    };
  };

  const onSubmit = async (data) => {
    const result = await sendTodoItemUpdating(todoItem.id, data.description);

    if (result.status === 400) {
      handleFormErrors(result.body.errors, result.headers, setError, {}, toast);

      return;
    }

    if (result.status === 200) {
      toast({
        title: "L'action à faire a bien été éditée.",
        status: 'success',
        isClosable: true,
      });

      dropEdition();
      onChange();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        display={{ base: 'block', md: 'flex' }}
        justifyContent="space-between"
        marginBottom={0}
        pl={4}
        pr={4}
        py={2}
        borderLeft="solid .125rem transparent"
        _hover={{
          borderLeftColor: TERTIARY,
        }}
      >
        <Box flexGrow={1} marginRight=".5em">
          <Grid
            alignItems="center"
            width="100%"
            display={{
              base: 'block',
              md: 'grid',
            }}
            gap=".5em"
            templateColumns="15ch auto"
            textAlign={{
              base: 'center',
              md: 'inherit',
            }}
          >
            <Box flexGrow={1}>
              <FormInput
                {...register('description', {
                  required: true,
                })}
                iconName="contracts"
                placeholder="Action à faire"
                labelWidth="10em"
                errorMessage={
                  errors?.description && (
                    <FormErrors errors={errors.description} />
                  )
                }
                formgroupstatus={errors?.description && 'invalid'}
                height="auto"
                width="100%"
                fontWeight="bold"
                paddingLeft=".25rem"
                paddingRight="2rem"
                autoFocus
                display="block"
                borderColor={GRAY_VARIANT}
              />
            </Box>
          </Grid>
        </Box>
        <Flex gap={1} marginTop={{ base: '.5rem', md: 0 }}>
          <AgiresButton
            iconName="checked-box"
            colorScheme={SUCCESS_SCHEME}
            size="xs"
            minWidth={1}
            paddingInlineStart=".25em"
            paddingInlineEnd="0"
            title="Valider"
            type="submit"
          >
            <VisuallyHidden>Valider</VisuallyHidden>
          </AgiresButton>
          <AgiresButton
            iconName="remove"
            colorScheme={GRAY_SCHEME}
            size="xs"
            minWidth={1}
            paddingInlineStart=".25em"
            paddingInlineEnd="0"
            title="Annuler"
            onClick={() => {
              dropEdition();
            }}
          >
            <VisuallyHidden>Annuler</VisuallyHidden>
          </AgiresButton>
        </Flex>
      </Flex>
    </form>
  );
}
