export function handleFormErrors(
  errors,
  headers,
  setError,
  modelFormFieldNamesMap,
  toast
) {
  if (headers.get('Content-Type') === 'application/problem+json') {
    toast({
      title: "Une erreur est survenue, veuillez contacter l'administrateur.",
      status: 'error',
      isClosable: true,
    });

    return;
  }

  toast({
    title: 'Le formulaire est invalide.',
    status: 'error',
    isClosable: true,
  });

  handleErrors(errors, setError, modelFormFieldNamesMap);
}

function handleErrors(
  errors,
  setError,
  modelFormFieldNamesMap,
  baseFieldName = ''
) {
  if (errors) {
    Object.entries(errors).forEach(([fieldName, message]) => {
      const resolvedFieldName =
        fieldName in modelFormFieldNamesMap
          ? modelFormFieldNamesMap[fieldName]
          : fieldName;

      if (typeof message === 'string') {
        setError(`${baseFieldName}${resolvedFieldName}`, {
          type: 'manual',
          types: {
            manual: message,
          },
        });
        return;
      }

      handleErrors(
        message,
        setError,
        modelFormFieldNamesMap,
        baseFieldName + resolvedFieldName + '.'
      );
    });
  }
}
