import Layout from '../components/app/Layout';
import { Flex, Grid, Text } from '@chakra-ui/layout';
import { Card } from '../components/Card';
import { InfoCard } from '../components/InfoCard';
import React, { Fragment, useState } from 'react';
import {
  DANGER,
  LIGHT,
  PRIMARY,
  SECONDARY,
  SECONDARY_LIGHTER,
  SUCCESS_SCHEME,
} from '../appTheme';
import querystring from 'qs';
import { useQuery } from '../hooks/apiClient';
import { useCurrentUser } from '../hooks/authenticationStatus';
import { InfoCardCell } from '../components/InfoCardCell';
import NextLink from 'next/link';
import FormattedDate from '../components/Date/FormattedDate';
import AddTodoItemForm from '../components/app/user/AddTodoItemForm';
import { AgiresButton } from '../components/AgiresButton';
import TodoItem from '../components/app/user/TodoItem';
import useNow from '../hooks/useNow';
import { useMemo } from 'react';
import InfoCardMailsCell from '../components/app/mail/InfoCardMailsCell';

export default function Home() {
  const user = useCurrentUser();
  const [displayTodoItemForm, setDisplayTodoItemForm] = useState(null);

  const now = useNow();

  const [userTodoListLoaded, , userTodoList, , userTodoListLoadData] = useQuery(
    '/api/users/me?includes=avatarUrl,contact,todoList'
  );

  const [tasksInProgressLoaded, , tasksInProgress] = useQuery(
    '/api/tasks?' +
      querystring.stringify({
        itemsPerPage: 5,
        page: 1,
        includes: 'taskRecipients',
        filters: {
          recipientIdsIn: user.contact.id,
          state: 'TO_DO',
        },
      })
  );

  const [tasksNotToConnectedUserLoaded, , tasksNotToConnectedUser] = useQuery(
    '/api/tasks?' +
      querystring.stringify({
        itemsPerPage: 5,
        page: 1,
        includes: 'taskRecipients',
        filters: {
          recipientIdsNotIn: user.contact.id,
          state: 'TO_DO',
        },
      })
  );

  const [eventsLoaded, , events] = useQuery(
    '/api/events?' +
      querystring.stringify({
        itemsPerPage: 5,
        page: 1,
        filters: {
          fromStartDate: now.ymd,
        },
        orders: '-creationDate',
      })
  );

  const [appointmentsLoaded, , appointments] = useQuery(
    '/api/appointments?' +
      querystring.stringify({
        itemsPerPage: 5,
        page: 1,
        filters: {
          fromDate: now.ymd,
        },
        orders: '-date',
      })
  );

  const [contractsLoaded, , contracts] = useQuery(
    '/api/contracts?' +
      querystring.stringify({
        itemsPerPage: 5,
        page: 1,
        includes: 'type,company',
        filters: {
          signed: false,
        },
        orders: '-creationDate',
      })
  );

  const [contactsLoaded, , contacts] = useQuery(
    '/api/contacts?' +
      querystring.stringify({
        itemsPerPage: 5,
        page: 1,
        orders: '-creationDate',
        filters: {
          noUser: '1',
        },
      })
  );

  const [documentsLoaded, , documents] = useQuery(
    '/api/documents?includes=currentVersion&' +
      querystring.stringify({
        itemsPerPage: 5,
        page: 1,
        orders: '-creationDate',
      })
  );

  const mailQueryString = useMemo(() => {
    const searchQueryString = querystring.stringify({
      includes: 'mailRecipients,author,documentLinks.documentVersion.file',
      filters: {
        sent: '1',
      },
      itemsPerPage: 5,
    });

    return '/api/mails?' + searchQueryString;
  }, []);

  const [mailsLoaded, , mails] = useQuery(mailQueryString);

  return (
    <Layout>
      <Flex
        flexDirection={{ base: 'column', md: 'revert' }}
        gap="2rem"
        marginBottom="3rem"
        width="100%"
        alignItems="center"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
      >
        <Card
          iconName="calendar"
          color={LIGHT}
          cardType="date"
          cardDateDay={now.dayNumber}
          cardDate={now.monthAndYear}
          backgroundColor={SECONDARY}
          href="/"
        />
        <Card
          iconName="mail-object"
          cardInfoText="Contrats en attente"
          cardNumberValue={contractsLoaded && contracts.items.length}
          color={PRIMARY}
          backgroundColor={LIGHT}
          linkLabel="TOUS LES CONTRATS"
          href="/contrats"
        />
        <Card
          iconName="events"
          cardInfoText="Événements à venir"
          cardNumberValue={eventsLoaded && events.items.length}
          color={PRIMARY}
          backgroundColor={LIGHT}
          linkLabel="TOUS LES EVENEMENTS"
          href="/evenements"
        />
        {/*<Card*/}
        {/*  iconName="payment"*/}
        {/*  cardInfoText="Versements reçus"*/}
        {/*  cardNumberValue={0}*/}
        {/*  color={PRIMARY}*/}
        {/*  backgroundColor={LIGHT}*/}
        {/*  linkLabel="TOUS LES VERSEMENTS"*/}
        {/*  href="/"*/}
        {/*/>*/}
      </Flex>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        gap="2rem"
        marginBottom="2rem"
        width="100%"
        alignItems={{ base: 'center', md: 'stretch' }}
      >
        <InfoCard
          linkAs={NextLink}
          cardTitle="Mes tâches en cours"
          cardIconName="reminder"
          linkTitle="> TOUTES MES TÂCHES"
          linkHref={`/taches?recipientsIn=${user.contact.id}`}
        >
          {tasksInProgressLoaded &&
            tasksInProgress.items.map((task) => (
              <InfoCardCell
                as={NextLink}
                key={task.id}
                date={
                  task.dueDate ? <FormattedDate date={task.dueDate} /> : null
                }
                hasNotificationDot={task?.dueDate && task.dueDate < now}
                notificationDotColor={DANGER}
                href={`/taches/${task.id}/detail`}
                hasBgWhite
              >
                <Text>{task.name}</Text>
              </InfoCardCell>
            ))}
          {tasksInProgressLoaded && !tasksInProgress.items.length && (
            <InfoCardCell hasBgWhite>
              <Text>Pas de tâche en cours</Text>
            </InfoCardCell>
          )}
        </InfoCard>
        <InfoCard
          linkAs={NextLink}
          cardTitle="Tâches de l'école"
          cardIconName="tasks"
          linkTitle="> TOUTES LES TÂCHES"
          headerColor={SECONDARY}
          linkBackgroundColor={SECONDARY_LIGHTER}
          flexDirection="row"
          linkHref={`/taches?recipientsNotIn=${user.contact.id}`}
        >
          {tasksNotToConnectedUserLoaded &&
            tasksNotToConnectedUser.items.map((task) => (
              <InfoCardCell
                as={NextLink}
                key={task.id}
                date={
                  task.dueDate ? <FormattedDate date={task.dueDate} /> : null
                }
                hasNotificationDot={task?.dueDate && task.dueDate < now}
                notificationDotColor={DANGER}
                href={`/taches/${task.id}/detail`}
                hasBgWhite
              >
                <Text>
                  {task.name}{' '}
                  {task.taskRecipients.map((taskRecipient, index) => (
                    <Fragment key={taskRecipient.id}>
                      {index === 0 && <> {'>'} </>}
                      {taskRecipient.recipient.firstName}{' '}
                      {taskRecipient.recipient.lastName}
                      {index !== task.taskRecipients.length - 1 && <>,&nbsp;</>}
                    </Fragment>
                  ))}
                </Text>
              </InfoCardCell>
            ))}
          {tasksNotToConnectedUserLoaded &&
            !tasksNotToConnectedUser.items.length && (
              <InfoCardCell hasBgWhite>
                <Text>Pas de tâche attribuée</Text>
              </InfoCardCell>
            )}
        </InfoCard>
      </Grid>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        gap="2rem"
        marginBottom="2rem"
        width="100%"
        alignItems={{ base: 'center', md: 'stretch' }}
      >
        <InfoCard
          linkAs={NextLink}
          cardTitle="Rendez-vous à venir"
          cardIconName="calendar"
          linkTitle="> TOUS LES RENDEZ-VOUS"
          linkHref={`/rendez-vous`}
          flexDirection="row"
          headerColor={SECONDARY}
          linkBackgroundColor={SECONDARY_LIGHTER}
        >
          {appointmentsLoaded &&
            appointments.items.map((appointment) => (
              <InfoCardCell
                as={NextLink}
                key={appointment.id}
                date={
                  appointment.date ? (
                    <FormattedDate date={appointment.date} />
                  ) : null
                }
                href={`/rendez-vous/${appointment.id}/detail`}
                hasBgWhite
              >
                <Text>{appointment.reason.name}</Text>
              </InfoCardCell>
            ))}
          {appointmentsLoaded && !appointments.items.length && (
            <InfoCardCell hasBgWhite>
              <Text>Pas de rendez-vous à venir</Text>
            </InfoCardCell>
          )}
        </InfoCard>
        <InfoCard
          linkAs={NextLink}
          cardTitle="Evénements à venir"
          cardIconName="events"
          linkTitle="> TOUS LES EVENEMENTS"
          linkHref={`/evenements`}
          flexDirection="row"
        >
          {eventsLoaded &&
            events.items.map((event) => (
              <InfoCardCell
                as={NextLink}
                key={event.id}
                date={
                  (event.startDate
                    ? new Date(event.startDate).toLocaleDateString()
                    : null) +
                  ' ' +
                  (event.endDate
                    ? new Date(event.endDate).toLocaleDateString()
                    : null)
                }
                href={`/evenements/${event.id}/detail`}
                hasBgWhite
              >
                <Text>{event.name}</Text>
              </InfoCardCell>
            ))}
          {eventsLoaded && !events.items.length && (
            <InfoCardCell hasBgWhite>
              <Text>Pas d'événement à venir</Text>
            </InfoCardCell>
          )}
        </InfoCard>
      </Grid>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        gap="2rem"
        marginBottom="2rem"
        width="100%"
        alignItems={{ base: 'center', md: 'stretch' }}
      >
        <InfoCard
          linkAs={NextLink}
          cardTitle="Contrats en attente de signature"
          cardIconName="contracts"
          linkTitle="> TOUS LES CONTRATS"
          linkHref={`/contrats`}
          linkBackgroundColor={SECONDARY_LIGHTER}
          flexDirection="row"
        >
          {contractsLoaded &&
            contracts.items.map((contract) => (
              <InfoCardCell
                as={NextLink}
                key={contract.id}
                date={
                  contract.creationDate ? (
                    <FormattedDate date={contract.creationDate} />
                  ) : null
                }
                href={`/contrats/${contract.id}/detail`}
                hasBgWhite
              >
                <Text>
                  {contract.company.name} - {contract.type.name} -{' '}
                  {contract.amount}€ ({contract.htOrTtc}){' '}
                </Text>
              </InfoCardCell>
            ))}
          {contractsLoaded && !contracts.items.length && (
            <InfoCardCell hasBgWhite>
              <Text>Pas de contrat</Text>
            </InfoCardCell>
          )}
        </InfoCard>
        <InfoCard
          linkAs={NextLink}
          cardTitle="Derniers contacts"
          cardIconName="contact"
          linkTitle="> TOUS LES CONTACTS"
          linkHref={`/contacts`}
          headerColor={SECONDARY}
          linkBackgroundColor={SECONDARY_LIGHTER}
          flexDirection="row"
        >
          {contactsLoaded &&
            contacts.items.map((contact) => (
              <InfoCardCell
                as={NextLink}
                key={contact.id}
                date={<FormattedDate date={contact.creationDate} />}
                href={`/contacts/${contact.id}/detail`}
                hasBgWhite
              >
                <Text>
                  {contact.firstName} {contact.lastName}
                </Text>
              </InfoCardCell>
            ))}
          {contactsLoaded && !contacts.items.length && (
            <InfoCardCell hasBgWhite>
              <Text>Pas de contacts</Text>
            </InfoCardCell>
          )}
        </InfoCard>
      </Grid>

      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        gap="2rem"
        marginBottom="2rem"
        width="100%"
        alignItems={{ base: 'center', md: 'stretch' }}
      >
        <InfoCard
          linkAs={NextLink}
          cardTitle="Derniers mails"
          cardIconName="message"
          linkTitle="> TOUS LES MAILS"
          linkHref={`/mails`}
          headerColor={SECONDARY}
          linkBackgroundColor={SECONDARY_LIGHTER}
          flexDirection="row"
        >
          {mailsLoaded &&
            mails.items.map((mail, index) => (
              <InfoCardMailsCell
                detailHref={`/mails/${mail.id}`}
                sendingDate={mail.sendingDate}
                title={mail.title}
                author={`${mail.author.firstName} ${mail.author.lastName}`}
                hasBgWhite
                recipients={mail.mailRecipients.map((mailRecipient) => {
                  const recipient = mailRecipient.recipient;

                  return `${recipient.firstName} ${recipient.lastName}`;
                })}
                documents={mail.documentLinks.map(
                  (documentLink) => documentLink.documentVersion
                )}
              />
            ))}
            {mailsLoaded && !mails.items.length && (
                <InfoCardCell hasBgWhite>
                    <Text>Pas de mails</Text>
                </InfoCardCell>
            )}
        </InfoCard>
        <InfoCard
          linkAs={NextLink}
          cardTitle="Derniers documents"
          cardIconName="documents"
          linkTitle="> TOUS LES DOCUMENTS"
          linkHref={`/documents`}
          linkBackgroundColor={SECONDARY_LIGHTER}
          flexDirection="row"
        >
          {documentsLoaded &&
            documents.items.map((document) => (
              <InfoCardCell
                as={NextLink}
                key={document.id}
                date={<FormattedDate date={document.creationDate} />}
                href={`/documents/${document.currentVersion.id}/detail`}
                hasBgWhite
              >
                <Text>{document.currentVersion.title}</Text>
              </InfoCardCell>
            ))}
          {documentsLoaded && !documents.items.length && (
            <InfoCardCell hasBgWhite>
              <Text>Pas de document</Text>
            </InfoCardCell>
          )}
        </InfoCard>
      </Grid>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
        }}
        gap="2rem"
        marginBottom="2rem"
        width="100%"
        alignItems={{ base: 'center', md: 'stretch' }}
      >
        <InfoCard
          linkAs={NextLink}
          cardTitle="Pense-bête"
          cardIconName="reminder"
        >
          {userTodoListLoaded &&
            userTodoList.todoList.map((todoItem) => (
              <TodoItem
                p={4}
                key={todoItem.id}
                todoItem={todoItem}
                removeTodoItem={userTodoListLoadData}
                onChange={userTodoListLoadData}
              />
            ))}
          {userTodoListLoaded && !userTodoList.todoList.length && (
            <InfoCardCell hasBgWhite>
              <Text>Pas de pense-bête actuellement</Text>
            </InfoCardCell>
          )}
          {displayTodoItemForm ? (
            <AddTodoItemForm
              onChange={userTodoListLoadData}
              user={user}
              dropForm={() => setDisplayTodoItemForm(false)}
            />
          ) : (
            <AgiresButton
              iconName="add"
              colorScheme={SUCCESS_SCHEME}
              size="sm"
              height="revert"
              py={2}
              display="flex"
              iconMargin="0 .25rem 0 0"
              borderRadius="0 0 1rem 1rem"
              onClick={() => setDisplayTodoItemForm(true)}
            >
              AJOUTER
            </AgiresButton>
          )}
        </InfoCard>
      </Grid>
    </Layout>
  );
}
