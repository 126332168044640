import { Button } from '@chakra-ui/react';
import { DANGER, DANGER_SCHEME } from '../appTheme';
import { Icon } from './Icon';

export default function RemoveButton({ title, onClick }) {
  return (
    <Button
      variant="outline"
      borderRadius=".5rem"
      p=".25rem"
      height="auto"
      mx="1rem"
      minWidth="1rem"
      backgroundColor="rgba(255,255,255,.01)"
      borderColor="transparent"
      color={DANGER}
      transition="backgroundColor, .3s"
      _hover={{
        color: 'white',
        backgroundColor: DANGER,
      }}
      colorScheme={DANGER_SCHEME}
      title={title || 'Supprimer'}
      onClick={onClick}
    >
      <Icon
        iconName="remove"
        iconAlt={title || 'Supprimer'}
        iconWidth="1.5rem"
      />
    </Button>
  );
}
