import React from 'react';
import {InputGroup, Box, Textarea, useTheme} from '@chakra-ui/react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { GRAY, GRAY_VARIANT, DARK, LIGHT, SUCCESS_DARKER, DANGER } from '../appTheme';
import { Icon } from './Icon';

export const FormTextArea = React.forwardRef(({
  name,
  isRequired, 
  label, 
  errorMessage, 
  iconName, 
  isDirectionColumn, 
  marginBottom, 
  labelWidth, 
  formgroupstatus, 
  placeholder, 
  textarearow, 
  id,
  whiteSpace,
  onBlur,
  onChange,
  gap,
 }, ref) => {
    const theme = useTheme();

    return (
      <FormControl 
        display="flex" 
        marginBottom={marginBottom ? marginBottom : "1em"} 
        flexDirection={{base: "column", md: isDirectionColumn ? "column" : "row"}}  
        gap={{ base: gap ? gap : ".25em" }}
        isDisabled={formgroupstatus === "disabled" ? true : false} 
        isRequired={isRequired} 
        width="100%" 
        isInvalid={formgroupstatus === "invalid" ? true : false}
        fontSize="1em"
      >
        {label &&
          <FormLabel
            display="flex"
            flexBasis={labelWidth ? labelWidth : "40%"}
            gap=".5rem"
            flexShrink={0}
            fontSize="1em"
            requiredIndicator={isRequired ? <Box as="span" color={DANGER} mt=".25em" ml="-.25rem">*</Box> : null}
          >
            {iconName &&
              <Icon
                iconName={iconName}
                margin=".5em 0 0 0"
                iconWidth="1.75em"
                iconColor={theme.colors.gray}
              />
            }
            <Box
              as="span"
              color={GRAY}
              fontWeight="bold"
              marginTop=".66666em"
              whiteSpace={whiteSpace}
              fontSize="1em"
            >
              {label}
            </Box>
          </FormLabel>
        }
        <InputGroup fontSize="1em">
           <Box width="100%">
            <Textarea
              name={name}
              padding=".5em"
              placeholder={placeholder}
              _placeholder={{ opacity: .4 }}
              backgroundColor={LIGHT}
              borderWidth="1px"
              rows={textarearow ? textarearow : null}
              color={DARK}
              ref={ref}
              fontSize="1em"
              onBlur={onBlur}
              onChange={onChange}
              id={id && id}
              borderColor={formgroupstatus === "success" ? SUCCESS_DARKER : GRAY_VARIANT}
            />
            <FormErrorMessage fontSize="1em">
              {errorMessage ? errorMessage : "Champ invalide"}
            </FormErrorMessage>
           </Box>
        </InputGroup>
      </FormControl>
    )
  }
);