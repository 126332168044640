import { Flex, Text } from '@chakra-ui/layout';
import { Icon } from '../../Icon';
import React from 'react';
import { InfoCardCell } from '../../InfoCardCell';
import FormattedDate from '../../Date/FormattedDate';
import RemoveButton from '../../RemoveButton';
import NextLink from 'next/link';

export default function InfoCardMailsCell({
  detailHref,
  sendingDate,
  title,
  author,
  recipients,
  documents,
  onUnlinkMail,
  hasBgWhite,
}) {
  const attachments = documents.map((document) => ({
    fileName: document.title,
    fileWeight: document.file.size / 1000 + ' ko',
    fileUrl: `/api/document-versions/${document.id}/download`,
  }));

  return (
    <Flex justifyContent="space-between">
      <InfoCardCell
        as={NextLink}
        href={detailHref}
        date={sendingDate ? <FormattedDate date={sendingDate} /> : '~'}
        hasBgWhite={hasBgWhite}
        attachments={attachments}
      >
        <Text as="strong">{title}</Text>
        <Text as="span" display="flex" alignItems="center">
          <Icon iconName="account" iconWidth="1.25rem" margin=".1rem 0 0 0" />
          <Text marginLeft=".25rem" as="span">
            Expéditeur : {author}
          </Text>
        </Text>
        <Text as="span" display="flex" alignItems="center">
          <Icon
            iconName="more-contacts"
            iconWidth="1.25rem"
            margin=".1rem 0 0 0"
          />
          <Text marginLeft=".25rem" as="span">
            Destinataire(s) :{' '}
            {recipients.map((recipient, index) =>
              index !== recipients.length - 1 ? `${recipient} ,` : recipient
            )}
          </Text>
        </Text>
      </InfoCardCell>
      {!sendingDate && onUnlinkMail && (
        <RemoveButton title="Délier ce document" onClick={onUnlinkMail} />
      )}
    </Flex>
  );
}
