import {Flex, Link, Text, useTheme} from '@chakra-ui/react';
import { Icon } from './Icon';
import { LIGHT, PRIMARY_LIGHTER, SECONDARY_GRADIENT } from '../appTheme';
import NextLink from "next/link";

export const Card = ({
  linkLabel,
  cardType,
  href,
  cardInfoText,
  cardDateDay,
  cardDate,
  iconName,
  cardNumberValue
}) => {
    const theme = useTheme();

    return <Flex
        direction="column"
        color={(cardType == "date") ? LIGHT : PRIMARY_LIGHTER}
        bg={cardType == "date" ? SECONDARY_GRADIENT : LIGHT}
        flexGrow={1}
        width="100%"
        maxWidth={{lg: "20rem"}}
        borderRadius="1.25rem"
        padding=".5rem .75rem"
        boxShadow='md'
    >
        <Flex
            flexGrow={1}
            align="center"
        >
            <Flex
                align="center"
            >
                <Icon
                    iconWidth="4.5rem"
                    iconColor={(cardType == "date") ? theme.colors.light : theme.colors.primary_lighter}
                    iconName={iconName}
                    margin="0 .5rem 0 0"
                />
                {cardType === 'date' ? null :
                    (
                        <Text
                            fontSize="1.25rem"
                            lineHeight="none"
                            fontWeight="bold"
                        >
                            {cardInfoText}
                        </Text>
                    )
                }
            </Flex>
            {cardType === 'date' ?
                <Flex
                    flexGrow={1}
                    justify="end"
                >
                    <Text
                        as="span"
                        display="flex"
                        alignItems="end"
                        fontSize="3.75rem"
                        fontWeight="600"
                    >
                        {cardDateDay}
                    </Text>
                    <Text
                        as="span"
                        display="flex"
                        textAlign="right"
                        justify="end"
                        alignItems="center"
                        marginTop="2.25rem"
                        marginLeft=".5rem"
                        fontSize=".875rem"
                        fontWeight="500"
                    >
                        {cardDate}
                    </Text>
                </Flex>
                :
                <Text
                    flexGrow={1}
                    fontSize="4rem"
                    fontWeight="300"
                    textAlign="right"
                    marginLeft="1rem"
                >
                    {cardNumberValue}
                </Text>
            }
        </Flex>
        {linkLabel && (
            <Link
                as={NextLink}
                href={href}
                fontWeight="600"
                textAlign="right"
                fontSize=".75rem"
            >
                &gt; {linkLabel}
            </Link>
        )}
    </Flex>
};