import { useMemo } from 'react';
import { toMonthAndYear, toYmd } from '../common/date/dateTransformer';

export default () => {
  return useMemo(() => {
    const date = new Date();
    return {
      dayNumber: date.getDate(),
      monthAndYear: toMonthAndYear(date),
      ymd: toYmd(date),
    };
  }, []);
};
