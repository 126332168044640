import { AgiresButton } from '../../AgiresButton';
import { GRAY_SCHEME, DANGER_SCHEME } from '../../../appTheme';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';

export default function ConfirmDeleteModal({
  title,
  isOpen,
  cancelRef,
  onClose,
  onConfirm,
}) {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Êtes-vous sûr(e) ? Cette opération est irréversible.
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="space-between">
            <AgiresButton
              ref={cancelRef}
              onClick={onClose}
              iconName="arrow-left"
              colorScheme={GRAY_SCHEME}
            >
              Annuler
            </AgiresButton>
            <AgiresButton
              onClick={onConfirm}
              iconName="remove"
              colorScheme={DANGER_SCHEME}
            >
              Supprimer
            </AgiresButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
